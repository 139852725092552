import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Container from 'react-bootstrap/Container';
import { TWayinFormPagePageComponentProps } from './models';
import './WayinFormPage.scss';

const WayinFormPage: FC<TWayinFormPagePageComponentProps> = ({
  data: {
    allWayinForm: {
      nodes: [pageData],
    },
    commonSettings,
    siteSettings,
    header,
    footer,
  },
}) => {
  const {
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoExternalHreflangs,
    openGraphImageUrl,
    name,
    wayinFormId,
    wayinFormSrc,
  } = pageData;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://xd.wayin.com/ui/ngx.embed.min.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      (window as any).NGX?.Embed?.ignore();
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
      hideNewsletter
    >
      <div className="wayin-form-page">
        <Container fluid>
          <h1 className="wayin-form-page__title">{name}</h1>
          <iframe
            title="Wayin form"
            src={wayinFormSrc}
            frameBorder="0"
            scrolling="no"
            width="100%"
            height="100%"
            id={wayinFormId}
          />
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query WayinFormPageQuery($link: String, $lang: String) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    allWayinForm(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        openGraphImageUrl
        name
        wayinFormId
        wayinFormSrc
      }
    }
  }
`;

export default WayinFormPage;
